/* xs (0px)
sm (600px)
md (900px)
lg (1200px)
xl (1536px) */

html, body {
  overflow-x: hidden;
}

/* Remove tawk.to we're here image */
img[src="https://embed.tawk.to/_s/v4/assets/images/attention-grabbers/168-r-br.svg"] {
  display: none!important;
}

.css-10v1rem {
  width: 100%;
  background-image: linear-gradient(180deg, #CEE5FD, #FFF);
  -webkit-background-size: 100% 20%;
  background-size: 100% 70%;
  background-repeat: no-repeat;
}

.episode-thumbnail {
  max-width: 100%;
}

.rhap_container {
  background: transparent !important;
  box-shadow: 0 0 1px rgba(85, 166, 246, 0.1),1px 1.5px 2px -1px rgba(85, 166, 246, 0.15),4px 4px 12px -2.5px rgba(85, 166, 246, 0.15) !important;
}

.rhap_controls-section {
  background: transparent !important;
}

.payment-method-img {
  max-width: 95%;
}

@media (max-width: 600px) {
  .css-1vags01 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #video-snippets {
    max-height: 300px;
  }
}

@media (min-width: 600px) {
  #video-snippets {
    max-height: 600px;
  }

  #not-found, #not-found-podcast {
    justify-content: center;
  }
}

@media (min-width: 900px) {

}

@media (min-width: 1200px) {

}

@media (min-width: 1536px) {

}